import React from 'react';
import { StyledTitleRowContainer } from '@/components/common/styledComponents';
import DataTable from '@/components/common/organisms/DataTable';
import styled from 'styled-components';
import { PrivateApplicant } from '@/types/eduCamp/private';

const ReportCampPrivateDetailSummary = ({
	applicantData,
	isLoading,
}: {
	applicantData: PrivateApplicant;
	isLoading: boolean;
}) => {
	const getApplicantAttendance = (array: number[], week: number) => {
		if (!array || !array?.length) return '';
		const attendance = array[week - 1];
		if (attendance < 0) return '';
		return attendance === 1 ? 'O' : 'X';
	};

	const { applicant } = applicantData;
	const campWeekCount = applicant.attendances_detail.length;

	const createAttendanceObject = (weekCount: number) => {
		const attendanceObject: { xAxisTitle: string; [key: string]: string | JSX.Element } = { xAxisTitle: '출석' };
		for (let i = 1; i <= weekCount; i++) {
			attendanceObject[`${i}차`] =
				i % 2 === 0 && weekCount !== 3 ? (
					<StyledText>라이브세션 없음</StyledText>
				) : (
					getApplicantAttendance(applicant?.attendances_detail, i)
				);
		}
		return attendanceObject;
	};

	const createAssignmentObject = (weekCount: number) => {
		const assignmentObject: { xAxisTitle: string; [key: string]: string | JSX.Element } = { xAxisTitle: '과제' };
		for (let i = 1; i <= weekCount; i++) {
			assignmentObject[`${i}차`] =
				i === weekCount ? (
					<StyledText>과제 없음</StyledText>
				) : (
					getApplicantAttendance(applicant?.assignments_detail, i)
				);
		}
		return assignmentObject;
	};

	const data =
		campWeekCount === 3
			? [createAttendanceObject(3), createAssignmentObject(3)]
			: [createAttendanceObject(5), createAssignmentObject(5)];

	// 둘 중 하나
	// 모든 아이템에 key가 빠지지 않고 일정하게 들어간다면 노이슈
	const keyList = Object.keys(data[0]);
	return (
		<section>
			<StyledTitleRowContainer>요약정보</StyledTitleRowContainer>
			<DataTable data={data} keyList={keyList} isLoading={isLoading} width={'178px'} />
		</section>
	);
};

export default ReportCampPrivateDetailSummary;

const StyledText = styled.span`
	color: ${props => props.theme['gray-6']};
`;
