import React, { useEffect, useState } from 'react';
import { Col, ConfigProvider, DatePicker, Row } from 'antd';
import ResetButton from '@/components/common/molecules/ResetButton';
import { numberToLocalString } from '@/utils/number';
import DownloadButton from '@/components/common/atoms/DownloadButton';
import Text from '@/components/common/atoms/Text';
import theme from '@/styles/theme';
import { StyledBasicTable, StyledContentTitle } from '@/components/common/styledComponents';
import { StyledUserCountContainer, StyledDatePickerContainer, createColumns } from '@/components/UserList/UserList';
import { useDispatch } from 'react-redux';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getListHeaderSubTitle, getListHeaderTitle } from '@/utils/header';
import { mentoringLogExcelRequest } from '@/api/report/mentoring';
import { useNavigate, useParams } from 'react-router-dom';
import useRangeInput, { emptyRangeDate } from '@/hooks/useRangeInput';
import { animateScroll } from 'react-scroll';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import styled from 'styled-components';
import { dynamicRoutePath, ROUTER_PATH } from '@/utils/constants/paths';
import useContractId from '@/hooks/useContractId';
import { defaultTablePaginationConfig } from '@/utils/constants/table';
import EmptyTableSection from '@/components/common/atoms/EmptyTableSection';
import { downloadExcelFile } from '@/utils/download';
import { PRODUCTS } from '@/utils/constants/products';
import usePartnerName from '@/hooks/usePartnerName';
import { DATE_FORMAT, formatDate } from '@/utils/date';
import { MentoringLogData } from '@/types/mentoring/mentoring';
import { useMentoringLogQuery } from '@/queries/report/mentoring/useMentoringQuery';
import { MENTORING_LOG_INITIAL_DATA } from '@/utils/constants/mentoring';
const { RangePicker } = DatePicker;

const StyledNavigate = styled(Text)`
	cursor: pointer;
`;

const MentoringLog = () => {
	const navigate = useNavigate();
	const params = useParams();
	const contractId = useContractId();
	const dispatch = useDispatch();
	const partnerName = usePartnerName();
	const [page, setPage] = useState<number>(1);
	const { rangeDate, rangeDateString, onChange, resetRangeDate, isResetButtonDisabled } =
		useRangeInput(emptyRangeDate);
	const { data, isLoading } = useMentoringLogQuery(contractId, {
		...(rangeDateString[0] && { start_date: rangeDateString[0] }),
		...(rangeDateString[1] && { end_date: rangeDateString[1] }),
		page,
	});
	const {
		list: { data: mentoringLog },
		total_count: totalRegistrant,
	} = data?.data.result ?? MENTORING_LOG_INITIAL_DATA;
	const [isLoadingDownload, setIsLoadingDownload] = useState(false);

	useEffect(() => {
		dispatch(setHeaderTitle(getListHeaderTitle()));
		dispatch(setHeaderSubTitle(getListHeaderSubTitle('멘토링 사용 현황')));
	}, []);

	useEffect(() => {
		animateScroll.scrollToTop({
			delay: 100,
			duration: 100,
			smooth: true,
		});
	}, [contractId, rangeDateString, page]);

	const handleClickExcelDownloadButton = async () => {
		try {
			setIsLoadingDownload(true);
			const { data } = await mentoringLogExcelRequest(contractId, {
				...(rangeDateString[0] && { startDate: rangeDateString[0] }),
				...(rangeDateString[1] && { endDate: rangeDateString[1] }),
			});
			downloadExcelFile(data, `${partnerName}-${PRODUCTS.MENTORING}-이용 현황`);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoadingDownload(false);
		}
	};

	const list = [
		{
			title: '등록일',
			key: 'date',
			width: '143px',
			render: (date: string) => <>{!!date && formatDate(date, DATE_FORMAT.FULL_DATE)}</>,
		},
		{
			title: '카테고리',
			key: 'type',
			width: '134px',
		},
		{
			title: '고유번호',
			key: 'student_no',
			width: '128px',
		},
		{
			title: '세부정보',
			key: 'major',
			width: '128px',
		},
		{
			title: '이름',
			key: 'name',
			width: '128px',
		},
		{
			title: '상세내용',
			key: 'question',
			align: 'left' as const,
			width: '290px',
			render: (content_title: string, record: MentoringLogData) => {
				return (
					<StyledNavigate
						color={theme['link']}
						onClick={() =>
							navigate(
								dynamicRoutePath(ROUTER_PATH.REPORT.MENTORING.DETAIL, { ...params, id: record.no }),
							)
						}
					>
						<StyledContentTitle>{content_title}</StyledContentTitle>
					</StyledNavigate>
				);
			},
		},
		{
			title: '관심기업',
			tooltipContent:
				'유저가 멘토링 요청시 직접 입력한 기업입니다. 정보를 입력하지 않았다면 “모든 회사”로 표시됩니다.',
			width: '100px',
			key: 'company',
		},
		{
			title: '관심직무',
			tooltipContent:
				'유저가 멘토링 요청시 직접 입력한 직무입니다. 정보를 입력하지 않았다면 “모든 직무”로 표시됩니다.',
			width: '100px',
			key: 'job',
		},
	];

	// page size를 15로 변경
	const pageParams = { ...defaultTablePaginationConfig, total: totalRegistrant, pageSize: 15 };

	// table pagination, filter, sorting 발생 시 실행
	// 예시
	// { current: 2, pageSize: 25, total: 120 }
	const onTableChange = (pagination: TablePaginationConfig) => {
		// pagination이 없을 수 있다.
		setPage(pagination?.current || 1);
	};

	return (
		<>
			<Row>
				<StyledDatePickerContainer>
					<RangePicker
						value={rangeDate}
						onChange={onChange}
						allowClear={false}
						placeholder={['시작 기간', '종료 기간']}
					/>
					<ResetButton onClick={resetRangeDate} disabled={isResetButtonDisabled} />
				</StyledDatePickerContainer>
			</Row>
			<Row>
				<Col span={24}>
					<StyledUserCountContainer>
						<Text color={theme['gray-6']}>
							질문 횟수 : <Text color={theme['blue-7']}>{numberToLocalString(totalRegistrant)} </Text> 건
						</Text>
						<DownloadButton onClick={handleClickExcelDownloadButton} loading={isLoadingDownload} />
					</StyledUserCountContainer>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<ConfigProvider renderEmpty={() => <EmptyTableSection />}>
						<StyledBasicTable
							dataSource={mentoringLog}
							columns={createColumns(list)}
							pagination={pageParams}
							onChange={onTableChange}
							loading={isLoading}
							isLoading={isLoading}
							rowKey="no"
						/>
					</ConfigProvider>
				</Col>
			</Row>
		</>
	);
};
export default MentoringLog;
