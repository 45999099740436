import React, { useEffect, useState } from 'react';
import { Col, ConfigProvider, DatePicker, Row } from 'antd';
import ResetButton from '@/components/common/molecules/ResetButton';
import styled from 'styled-components';
import { numberToLocalString } from '@/utils/number';
import DownloadButton from '@/components/common/atoms/DownloadButton';
import Text from '@/components/common/atoms/Text';
import theme, { mixin } from '@/styles/theme';
import { StyledBasicTable } from '@/components/common/styledComponents';
import tableHeaderWithInfoCircle from '@/components/common/molecules/tableHeaderWithInfoCircle';
import { contentRegistrantsExcelRequest } from '@/api/report/content';
import useRangeInput from '@/hooks/useRangeInput';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { animateScroll } from 'react-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getListHeaderSubTitle, getListHeaderTitle } from '@/utils/header';
import { downloadExcelFile } from '@/utils/download';
import { defaultTablePaginationConfig } from '@/utils/constants/table';
import useContractId from '@/hooks/useContractId';
import { getPartnerName } from '@/store/contractSlice';
import EmptyTableSection from '@/components/common/atoms/EmptyTableSection';
import { DATE_FORMAT, formatDate } from '@/utils/date';
import { useUserListQuery } from '@/queries/report/common/useCommonQuery';
import { USER_LIST_INITIAL_DATA } from '@/utils/constants/common';
const { RangePicker } = DatePicker;

export const StyledDatePickerContainer = styled.div`
	${mixin.flexBox()};
	${mixin.alignItems('center')};
	margin-bottom: 24px;
`;

export const StyledUserCountContainer = styled.div`
	${mixin.flexBox()};
	${mixin.alignItems('center')};
	${mixin.justifyContent('space-between')};
	margin-bottom: 16px;
`;

interface TableColumnItem {
	title: string;
	tooltipContent?: string;
	key: string;
	dataIndex?: string | string[];
	width?: string | number;
	align?: 'center' | 'left' | 'right';
	render?: (value: string, record?: any, index?: number) => JSX.Element;
}

export const createColumns = (list: TableColumnItem[]) =>
	list.map(item => {
		return {
			title: () => (item.tooltipContent ? tableHeaderWithInfoCircle(item) : item.title),
			dataIndex: item.dataIndex ? item.dataIndex : item.key,
			align: item.align,
			width: item.width,
			render: item.render
				? item.render
				: (value: string) => (
						<Text color={theme['gray-9']} fontSize="14px">
							{value}
						</Text>
				  ),
		};
	});

const UserList = () => {
	const dispatch = useDispatch();
	const contractId = useContractId();
	const [page, setPage] = useState(1);
	const { rangeDate, rangeDateString, onChange, resetRangeDate, isResetButtonDisabled } = useRangeInput([null, null]);
	const { data, isLoading } = useUserListQuery(contractId, {
		...(rangeDateString[0] && { startDate: rangeDateString[0] }),
		...(rangeDateString[1] && { endDate: rangeDateString[1] }),
		page,
	});
	const { data: registrants, total: totalRegistrant } = data?.data ?? USER_LIST_INITIAL_DATA;

	const partnerName = useSelector(getPartnerName);

	useEffect(() => {
		dispatch(setHeaderTitle(getListHeaderTitle()));
		dispatch(setHeaderSubTitle(getListHeaderSubTitle('등록자 명단')));
	}, []);

	useEffect(() => {
		animateScroll.scrollToTop({
			delay: 100,
			duration: 100,
			smooth: true,
		});
	}, [contractId, rangeDateString, page]);

	// table pagination, filter, sorting 발생 시 실행
	// 예시
	// { current: 2, pageSize: 25, total: 120 }
	const onTableChange = (pagination: TablePaginationConfig) => {
		// pagination이 없을 수 있다.
		setPage(pagination?.current || 1);
	};

	const onClick = async () => {
		try {
			const response = await contentRegistrantsExcelRequest(contractId, {
				...(rangeDateString[0] && { startDate: rangeDateString[0] }),
				...(rangeDateString[1] && { endDate: rangeDateString[1] }),
			});
			downloadExcelFile(response.data, `${partnerName}-등록자 명단`);
		} catch (error) {
			console.error(error);
		}
	};

	const list = [
		{
			title: '가입일',
			key: 'joinDate',
			width: '134px',
			render: (joinDate: string) => <>{formatDate(joinDate, DATE_FORMAT.FULL_DATE)}</>,
		},
		{
			title: '제휴 등록일',
			key: 'registrationDate',
			width: '134px',
			render: (registrationDate: string) => <>{formatDate(registrationDate, DATE_FORMAT.FULL_DATE)}</>,
		},
		{
			title: '고유번호',
			key: 'studentNumber',
			width: '128px',
			render: (studentNum?: string) => <>{!studentNum?.includes('initial-student-number') && studentNum}</>,
		},
		{
			title: '세부정보',
			key: 'major',
			width: '100px',
		},
		{
			title: '이름',
			key: 'name',
			width: '100px',
		},
		{
			title: '유저번호',
			tooltipContent:
				'코멘토에서 사용자를 식별하기 위한 고유번호입니다. 특정 사용자에게 문제가 있다면 담당자에게 해당 사용자의 유저번호를 알려주세요.',
			key: 'userNumber',
			width: '100px',
			render: (userNum: string) => <>{!!userNum && userNum}</>,
		},
		{
			title: '이메일',
			key: 'email',
			width: '159px',
		},
		{
			title: '휴대폰 번호',
			key: 'mobile',
			width: '159px',
		},
	];

	return (
		<>
			<Row>
				<StyledDatePickerContainer>
					<RangePicker
						value={rangeDate}
						onChange={onChange}
						allowClear={false}
						placeholder={['시작 기간', '종료 기간']}
					/>
					<ResetButton onClick={resetRangeDate} disabled={isResetButtonDisabled} />
				</StyledDatePickerContainer>
			</Row>
			<Row>
				<Col span={24}>
					<StyledUserCountContainer>
						<Text color={theme['gray-6']}>
							등록자 수 : <Text color={theme['blue-7']}>{numberToLocalString(totalRegistrant)} </Text> 명
						</Text>
						<DownloadButton onClick={onClick} />
					</StyledUserCountContainer>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<ConfigProvider renderEmpty={() => <EmptyTableSection />}>
						<StyledBasicTable
							dataSource={registrants}
							columns={createColumns(list)}
							rowKey={'userNumber'}
							pagination={{
								...defaultTablePaginationConfig,
								total: totalRegistrant,
							}}
							isLoading={isLoading}
							onChange={onTableChange}
							loading={isLoading}
						/>
					</ConfigProvider>
				</Col>
			</Row>
		</>
	);
};
export default UserList;
