import React, { MouseEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import InfoCircleWithTooltip from '@/components/common/molecules/InfoCircleWithTooltip';
import theme from '@/styles/theme';
import ReportVODTable from '@/components/report/vod/organisms/ReportVODTable';
import { TablePaginationConfig } from 'antd';
import useContractId from '@/hooks/useContractId';
import { downloadExcelFile } from '@/utils/download';
import dayjs from 'dayjs';
import { getContractInfo } from '@/store/contractSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderTitle, setHeaderSubTitle } from '@/store/headerSlice';
import { getListHeaderTitle, getListHeaderSubTitle } from '@/utils/header';
import { fetchVODLogsExcel } from '@/api/report/vod';
import { PRODUCTS } from '@/utils/constants/products';
import { DATE_FORMAT } from '@/utils/date';
import Text from '@/components/common/atoms/Text';
import { useVodLog } from '@/queries/report/vod/useVodQuery';
import { VODTableRow } from '@/types/vod/vod';
import { VOD_LOG_INITIAL_DATA } from '@/utils/constants/vod';

interface VODLog extends VODTableRow {
	id: number;
	date: string;
	user_no: number;
	student_no: string;
	major: string;
	title: string;
	vod_no: number;
	totalPlayTime: string;
	completion: string;
}

export interface VODLogResponse {
	list: { total: number; data: VODLog[] };
	total: number;
}

const Over70Tooltip = styled(InfoCircleWithTooltip)`
	margin-left: 4px;
`;

const VODLog = () => {
	const dispatch = useDispatch();
	const contractId = useContractId();
	const contractInfo = useSelector(getContractInfo);
	const [isLoadingDownload, setLoadingDownload] = useState(false);
	const [page, setPage] = useState(1);
	const { data, isLoading, isRefetching } = useVodLog(contractId, page);
	// 학습자 수
	const { list, total } = data ?? VOD_LOG_INITIAL_DATA;
	const totalRegistrants = total;
	const logs = list.data;
	// VOD 재생 이력 총 수
	const totalLog = list.total;
	useEffect(() => {
		dispatch(setHeaderTitle(getListHeaderTitle()));
		dispatch(setHeaderSubTitle(getListHeaderSubTitle('VOD 재생 이력')));
	}, [contractInfo]);

	const columns = [
		{
			title: '재생일',
			dataIndex: 'date',
			key: 'date',
			render: (date: string) => <>{!!date && dayjs(date).format(DATE_FORMAT.FULL_DATE)}</>,
		},
		{
			title: '고유번호',
			dataIndex: 'student_no',
			key: 'student_no',
		},
		{
			title: '세부정보',
			dataIndex: 'major',
			key: 'major',
		},
		{
			title: 'VOD 제목',
			dataIndex: 'title',
			key: 'title',
		},
		{
			title: '누적 재생 시간 (분)',
			dataIndex: 'playtime',
			key: 'playtime',
		},
		{
			title: (
				<>
					상태
					<Over70Tooltip
						tooltipColor={theme['gray-9']}
						iconColor={theme['gray-9']}
						tooltipContent="VOD를 70% 이상 시청한 경우에만 수료 상태로 표기됩니다."
						tooltipPlacement="bottomLeft"
					/>
				</>
			),
			dataIndex: 'completion',
			key: 'completion',
			render: (completion: string) => (
				<>
					{completion === '미수료' ? <Text color={theme['volcano-6']}>{completion}</Text> : <>{completion}</>}
				</>
			),
		},
	];

	const onClickDownload = async (event: MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setLoadingDownload(true);
		const data = await fetchVODLogsExcel(contractId);
		downloadExcelFile(
			data,
			`${contractInfo.partnerName}-${dayjs().format('YYYY-MM-DD')}-${PRODUCTS.VOD}-재생 이력`,
		);
		setLoadingDownload(false);
	};

	const onChangePage = ({ current }: TablePaginationConfig) => {
		const newPage = current === undefined ? 1 : current;
		setPage(newPage);
	};

	return (
		<ReportVODTable<VODLog>
			dataSource={logs}
			columns={columns}
			total={totalLog}
			isRefetching={isRefetching}
			totalRegistrants={totalRegistrants}
			loading={isLoading}
			loadingDownload={isLoadingDownload}
			rowKey="id"
			onClickDownload={onClickDownload}
			onPageChange={onChangePage}
		/>
	);
};

export default VODLog;
