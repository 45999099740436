import axiosClient from '@/api';

export interface loginRequestDataTypes {
	user_id: string;
	user_pw: string;
}
export const loginRequest = async (data: loginRequestDataTypes) => {
	return await axiosClient.post('/login', data);
};

export const logoutRequest = async () => {
	return await axiosClient.post('/logout');
};

export interface affListRequestParamsTypes {
	affPartner: number;
}
export const affListRequest = async ({ affPartner }: affListRequestParamsTypes) => {
	return await axiosClient.get(`/main/${affPartner}`);
};

export const contractInfoRequest = async (contractId: number) => {
	return await axiosClient.get(`/main/contract/${contractId}`);
};

export const loadingSpinnerRequest = async () => {
	return await fetch('https://comento-s3-bucket.s3.ap-northeast-2.amazonaws.com/images/loading-spinner.json').then(
		response => response.json(),
	);
};

export const getCerficate = (applicantNo?: number) => {
	return axiosClient.get('https://comento.kr/api/edu/download/certificate', {
		params: { applicant_no: applicantNo },
		responseType: 'blob',
	});
};

export const getJumpUpCerficate = (applicantNo?: number) => {
	return axiosClient.get('https://comento.kr/api/edu/download/jump-up-certificate', {
		params: { applicant_no: applicantNo },
		responseType: 'blob',
	});
};

export const getSignedCookie = (assignmentNo?: number) => {
	return axiosClient.get(`/signedCookie/${assignmentNo}`);
};
