import React, { useEffect, useState } from 'react';
import { Button, Col, Row, ConfigProvider } from 'antd';
import { StyledUserCountContainer } from '@/components/UserList/UserList';
import Text from '@/components/common/atoms/Text';
import theme, { mixin } from '@/styles/theme';
import { numberToLocalString } from '@/utils/number';
import { StyledBasicTable, StyledCompletionState } from '@/components/common/styledComponents';
import styled from 'styled-components';
import CampProgressCard from '@/components/common/atoms/CampProgressCard';
import TableHeaderWithInfoCircle from '@/components/common/molecules/tableHeaderWithInfoCircle';
import { Link, useParams } from 'react-router-dom';
import { dynamicRoutePath, ROUTER_PATH } from '@/utils/constants/paths';
import { downloadApplicantToExcel } from '@/api/report/campPrivate';
import useContractId from '@/hooks/useContractId';
import { downloadExcelFile } from '@/utils/download';
import usePartnerName from '@/hooks/usePartnerName';
import { setHeaderSubTitle, setHeaderTitle } from '@/store/headerSlice';
import { getListHeaderSubTitle, getListHeaderTitle } from '@/utils/header';
import { useDispatch } from 'react-redux';
import EmptyTableSection from '@/components/common/atoms/EmptyTableSection';
import { PRODUCTS } from '@/utils/constants/products';
import { DATE_FORMAT, formatDate } from '@/utils/date';
import DownloadButton from '@/components/common/atoms/DownloadButton';
import { Progress } from '@/types/eduCamp/private';
import { useEduCampPrivateUserListQuery } from '@/queries/report/eduCampPrivate/useEduCampPrivateQuery';
import { EDU_CAMP_APPLICANT_LIST_INITIAL_DATA } from '@/utils/constants/eduCamp/eduCampCommon';
import { openCertificate } from '@/utils/certificate';
import useWaitCursor from '@/hooks/useWaitCursor';

export interface Record {
	name?: string;
	user_name?: string;
	user_no?: number;
	certification?: string;
	applicant_no?: number;
	complete?: number;
	completion?: number;
	no?: number;
}

interface RecordDate {
	edu_camp?: {
		start_date: string;
		end_date: string;
	};
}

const StyledDateString = styled.p`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: ${props => props.theme['gray-9']};
`;

const StyledCampTitle = styled.p`
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	${mixin.ellipsis(2)}
`;

const DetailLinkBox = styled.div`
	${mixin.flexBox()};
	flex-direction: column;
`;

const EmailWrapper = styled.div`
	word-break: break-word;
	word-wrap: break-word;
`;

const EduCampPrivateUserList = () => {
	const contractId = useContractId();
	const { data, isLoading } = useEduCampPrivateUserListQuery(contractId);
	const applicantList = data?.data ?? EDU_CAMP_APPLICANT_LIST_INITIAL_DATA;
	const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false);
	const partnerName = usePartnerName();
	const dispatch = useDispatch();
	const params = useParams();
	const stateInfoTemplate = `시작전 : 아직 캠프가 시작하지 않았습니다.\n\n진행중 : 캠프가 진행 중입니다.\n\n수료 심사중 : 캠프가 종료되어 수료 기준 충족 여부를 심사하고 있습니다. (심사까지 최대 7일이 소요됩니다.)\n\n수료 : 캠프 종료 후 수료 기준을 충족하여 수료증을 발급할 수 있습니다.\n\n미수료 : 캠프가 종료되었으나 수료 기준을 충족하지 못했습니다.\n\n취소 : 캠프 진행 도중, 신청자가 수강을 취소하였습니다.`;
	const progressInfoTemplate = `파란색 : 완료 상태입니다.\n\n빨간색 : 미완료 상태입니다.\n\n회색 : 아직 진행되지 않은 주차입니다.`;
	const infoTooltipScript = '수료증은 신청자의 상태가 수료인 경우에만 조회할 수 있습니다.';

	const getDateString = (startDate?: string, endDate?: string) => {
		if (!startDate || !endDate) return;
		return `${formatDate(startDate, DATE_FORMAT.FULL_DATE)} ~ ${formatDate(endDate, DATE_FORMAT.FULL_DATE)}`;
	};

	const onClickCertificate = useWaitCursor(openCertificate);

	const list = [
		{
			title: '이름',
			dataIndex: 'name',
			key: 'name',
			width: '10.85%',
			align: 'center' as const,
		},
		{
			title: '캠프 진행일',
			dataIndex: 'date',
			key: 'date',
			width: '11.64%',
			align: 'center' as const,
			render: (_: string, record: RecordDate) => (
				<StyledDateString>
					{getDateString(record.edu_camp?.start_date, record.edu_camp?.end_date)}
				</StyledDateString>
			),
		},
		{
			title: '이메일',
			dataIndex: 'email',
			key: 'email',
			width: '11.98%',
			align: 'center' as const,
			render: (text: string) => (
				<EmailWrapper>
					{text ? (
						<>
							{text.split('@')[0]}
							<br />
							{`@${text.split('@')[1]}`}
						</>
					) : (
						''
					)}
				</EmailWrapper>
			),
		},
		{
			title: '휴대폰 번호',
			dataIndex: 'mobile',
			key: 'mobile',
			width: '13.21%',
			align: 'center' as const,
		},
		{
			title: '신청 캠프',
			dataIndex: ['edu_camp', 'edu_camp_group', 'title'],
			key: 'applied_camp',
			width: '23.67%',
			align: 'left' as const,
			render: (campTitle: string) => <StyledCampTitle>{campTitle}</StyledCampTitle>,
		},
		{
			title: () => {
				return (
					<TableHeaderWithInfoCircle
						title={'수강 현황'}
						tooltipContent={progressInfoTemplate}
						tooltipPlacement={'bottom'}
					/>
				);
			},
			dataIndex: 'progress',
			key: 'progress',
			width: '11.98%',
			align: 'center' as const,
			render: (_: string, record: Progress) => (
				<CampProgressCard
					isPrivate
					assignment={record.assignments_detail}
					attendance={record.attendances_detail}
					campWeek={record.camp_week}
					key={`progress-card-${record.no}`}
				/>
			),
		},
		{
			title: () => (
				<TableHeaderWithInfoCircle
					title={'내역조회'}
					tooltipContent={infoTooltipScript}
					tooltipPlacement={'bottomLeft'}
				/>
			),
			dataIndex: 'action',
			key: 'action',
			width: '10.26%',
			align: 'center' as const,
			render: (_: string, record: Record) => (
				<>
					{!!record.name && (
						<DetailLinkBox>
							<Button
								disabled={!record.completion}
								type={'link'}
								onClick={() => onClickCertificate(record.no)}
							>
								수료증
							</Button>
							<Link
								to={dynamicRoutePath(
									`${ROUTER_PATH.REPORT.EDU_CAMP_PRIVATE.USER_LIST}/${record.no}`,
									params,
								)}
							>
								수강 현황
							</Link>
						</DetailLinkBox>
					)}
				</>
			),
		},
	];

	const stateList = {
		title: () => {
			return (
				<TableHeaderWithInfoCircle
					title={'상태'}
					tooltipContent={stateInfoTemplate}
					tooltipPlacement={'bottom'}
				/>
			);
		},
		dataIndex: 'state_detail',
		key: 'state_detail',
		width: '7.59%',
		align: 'center' as const,
		tooltipContent: stateInfoTemplate,
		render: (completion: string) => (
			<StyledCompletionState completion={completion}>{completion}</StyledCompletionState>
		),
	};
	// 3주 개설형 직부캠의 경우 상태 column을 노출하지 않는다
	const resultColumns = applicantList.list.some(record => record.camp_week === 3)
		? list
		: [...list.slice(0, 5), stateList, ...list.slice(5)];

	const downloadCampPrivateApplicantExcel = async () => {
		try {
			setIsDownloadLoading(true);
			const { data } = await downloadApplicantToExcel({ contractNo: contractId });
			downloadExcelFile(data, `${partnerName}-${PRODUCTS.EDU_CAMP_PRIVATE}-신청자 현황`);
		} catch (error) {
			console.error(error);
		} finally {
			setIsDownloadLoading(false);
		}
	};

	// 전체 다운로드 추가시 사용 예정
	// const onMenuClick: MenuProps['onClick'] = e => {
	// 	const download = e.key;
	// 	switch (download) {
	// 		case 'applicant':
	// 			return downloadCampPrivateApplicantExcel();
	// 		default:
	// 			return alert('준비중입니다');
	// 	}
	// };

	// const menu = (
	// 	<Menu
	// 		onClick={onMenuClick}
	// 		items={[
	// 			{
	// 				key: 'applicant',
	// 				label: '신청 명단 다운로드',
	// 			},
	// 			{
	// 				key: 'certification',
	// 				label: '수료증 다운로드',
	// 			},
	// 			{
	// 				key: 'assignment',
	// 				label: '과제 다운로드',
	// 			},
	// 		]}
	// 	/>
	// );

	useEffect(() => {
		dispatch(setHeaderTitle(getListHeaderTitle()));
		dispatch(setHeaderSubTitle(getListHeaderSubTitle('캠프 신청자 현황')));
	}, []);

	return (
		<>
			<Row>
				<Col span={24}>
					<StyledUserCountContainer>
						<Text color={theme['gray-6']}>
							등록자 수 : <Text color={theme['blue-7']}>{numberToLocalString(applicantList.count)} </Text>{' '}
							명
						</Text>
						{/*임시 로직 전체 다운로드 추가시 교체 예정*/}
						<DownloadButton
							loading={isLoading || isDownloadLoading}
							onClick={downloadCampPrivateApplicantExcel}
						>
							신청자 명단 다운로드
						</DownloadButton>
					</StyledUserCountContainer>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<ConfigProvider renderEmpty={() => <EmptyTableSection />}>
						<StyledBasicTable
							dataSource={applicantList.list}
							columns={resultColumns}
							pagination={false}
							loading={isLoading}
							isLoading={isLoading}
							rowKey={'no'}
						/>
					</ConfigProvider>
				</Col>
			</Row>
		</>
	);
};

export default EduCampPrivateUserList;
